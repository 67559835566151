<template>
  <div class="product card flex-card">
    <img :src="$api.image(product.imagePath)" alt="" class="avatar" />
    <div>
      <h6 :title="product.title" class="line-1">{{ product.title }}</h6>
      <small class="line-1 sub-content">{{ product.category.name }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      default: () => {},
    },
  },
};
</script>

<style></style>
