<template>
  <div
    v-if="totalLength > typesItemLength.length"
    class="home-section container"
  >
    <div class="home-left-side">
      <users-list></users-list>
      <div class="multiple-horizontal-card section-gap">
        <courses
          @return:length="RETURN_LENGTH('courses')"
          v-if="getSettings.isCourses === 1"
        ></courses>
        <Events
          @return:length="RETURN_LENGTH('events')"
          v-if="getSettings.isEvents === 1"
        ></Events>
        <ebook
          @return:length="RETURN_LENGTH('ebooks')"
          v-if="getSettings.isBooks === 1"
        ></ebook>
      </div>
    </div>
    <div class="home-right-side">
      <Orders inside :limit="3" />
      <ClosestEvents />
    </div>
  </div>
  <div v-else>
    <Setup />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Events from "./Events.vue";
import Courses from "./Courses.vue";
import Ebook from "./Ebook.vue";
import UsersList from "./UsersList.vue";
import ClosestEvents from "../ClosestEvents/index.vue";
import Setup from "./Setup/index.vue";
import Orders from "../ReportCenter/Orders/index.vue";
export default {
  components: {
    Setup,
    UsersList,
    Courses,
    Ebook,
    Events,
    ClosestEvents,
    Orders,
  },
  computed: {
    ...mapGetters(["getSettings"]),
  },
  data() {
    return {
      typesItemLength: [],
      totalLength: 0,
    };
  },
  methods: {
    RETURN_LENGTH(type) {
      const item = this.typesItemLength.find((t) => t === type);
      if (!item) this.typesItemLength.push(type);
    },
    SET_LENGTH() {
      if (this.getSettings.isBooks === 1) this.totalLength += 1;
      if (this.getSettings.isEvents === 1) this.totalLength += 1;
      if (this.getSettings.isCourses === 1) this.totalLength += 1;
      if (this.getSettings.isSeances === 1) this.totalLength += 1;
    },
  },
  mounted() {
    this.SET_LENGTH();
  },
};
</script>

<style></style>
