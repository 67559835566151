<template>
  <div>
    <card-overlay
      :loading="getCustomersAreLoading"
      v-if="getCustomers.length > 0"
      :empty="getCustomers.length === 0"
      mini
      :title="$t('lastUsers')"
    >
      <div>
        <div class="header-with-all-item">
          <h6>{{ $t("lastUsers") }} ({{ count }})</h6>
          <router-link to="/user-list"> {{ $t("viewAll") }} </router-link>
        </div>
        <div class="multiple-horizontal-card py-2">
          <router-link
            class="card flex-card"
            v-for="(user, i) in getCustomers"
            :to="`/user/${user.id}`"
            :key="i"
          >
            <img
              @error="(e) => (e.target.src = getSettings.defaultImage)"
              :src="$api.image(user.imagePath)"
              alt=""
              class="avatar"
            />
            <div>
              <p>{{ user.fullname }}</p>
              <p>
                <small class="sub-content">
                  {{ $t("createdDate") }} :
                  {{ formatter(user.createdAt) }}</small
                >
              </p>
            </div>
          </router-link>
        </div>
      </div>
    </card-overlay>
  </div>
</template>

<script>
import { formatter } from "gurhan/helpers/Date";
import CardOverlay from "../Shared/Overlays/CardOverlay.vue";

export default {
  components: {
    CardOverlay,
  },
  computed: {
    getCustomersAreLoading() {
      return this.$store.state.Customers.customersAreLoading;
    },
    getCustomers() {
      return this.$store.state.Customers.customers.filter(
        (c, i) => c.page === 1 && i < 10
      );
    },
    pages() {
      return Math.ceil(this.$store.state.Customers.count / this.limit);
    },
    count() {
      return this.$store.state.Customers.count;
    },
    getSettings() {
      return this.$store.state.User.settings;
    },
  },
  methods: {
    formatter,
    GET_LIST() {
      this.$store.dispatch("GET_CUSTOMERS", {
        page: 1,
        search: "",
        limit: 10,
      });
    },
  },
  mounted() {
    this.GET_LIST();
  },
};
</script>

<style></style>
