<template>
  <div>
    <GreetingPage @next:step="NEXT_STEP" v-if="step === 1" />
    <StartSubscription @next:step="NEXT_STEP" v-else-if="step === 2" />
    <CategoriesSetup @next:step="NEXT_STEP" v-else-if="step === 3" />
  </div>
</template>

<script>
import CategoriesSetup from "./Categories/index.vue";
import GreetingPage from "./GreetingPage";
import StartSubscription from "./StartSubscription";
export default {
  components: {
    GreetingPage,
    CategoriesSetup,
    StartSubscription,
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_STEP();
      },
    },
  },
  data() {
    return {
      step: 1,
    };
  },
  methods: {
    NEXT_STEP(step) {
      this.step = step;
      this.$router.push({ query: { step } });
    },
    SET_STEP() {
      const q = this.$route.query;
      if (!isNaN(Number(q.step))) {
        this.step = Number(q.step);
      } else this.step = 1;
    },
  },
  mounted() {
    this.SET_STEP();
  },
};
</script>

<style></style>
