<template>
  <card-overlay
    v-if="getBooks.length > 0 || getBooksAreLoading"
    :loading="getBooksAreLoading"
    :empty="getBooks.length === 0"
    :title="$t('books')"
    class="card card-horizontal-flex-2 perfect-card"
  >
    <div class="w-100">
      <div class="header-with-all-item">
        <h6>{{ $t("books") }}</h6>
        <router-link to="/type/books">{{ $t("viewAll") }} </router-link>
      </div>
      <div v-for="(book, i) in getBooks" :key="i" class="my-2">
        <router-link :to="`/product/books-${book.id}`">
          <product-card :product="book"></product-card>
        </router-link>
      </div>
    </div>
  </card-overlay>
</template>

<script>
import ProductCard from "../Cards/ProductCard.vue";
import CardOverlay from "../Shared/Overlays/CardOverlay.vue";
import Types from "../Type/Types";
export default {
  components: {
    CardOverlay,
    ProductCard,
  },
  mixins: [Types],
  computed: {
    getBooks() {
      return this.$store.state.Books.books.filter((c) => c.page === 1);
    },
    getBooksAreLoading() {
      return this.$store.state.Books.booksAreLoading;
    },
  },
  methods: {
    async GET_BOOKS() {
      const response = await this.$store.dispatch("GET_BOOKS", {
        limit: 10,
        page: 1,
        type: this.Types.find((type) => type.type === 4),
      });
      if (response.items.length === 0) this.$emit("return:length");
    },
  },
  mounted() {
    this.GET_BOOKS();
  },
};
</script>

<style></style>
