<template>
  <card-overlay
    v-if="getCourses.length > 0 || getCoursesAreLoading"
    :loading="getCoursesAreLoading"
    :empty="getCourses.length === 0"
    :title="$t('courses')"
    class="card card-horizontal-flex-2 perfect-card"
  >
    <div class="w-100">
      <div class="header-with-all-item">
        <h6>{{ $t("courses") }}</h6>
        <router-link to="/type/courses">{{ $t("viewAll") }} </router-link>
      </div>
      <div v-for="(course, i) in getCourses" :key="i" class="my-2">
        <router-link :to="`/product/courses-${course.id}`">
          <product-card :product="course"></product-card>
        </router-link>
      </div>
    </div>
  </card-overlay>
</template>

<script>
import ProductCard from "../Cards/ProductCard.vue";
import CardOverlay from "../Shared/Overlays/CardOverlay.vue";
import Types from "../Type/Types";
export default {
  components: {
    CardOverlay,
    ProductCard,
  },
  mixins: [Types],
  computed: {
    getCourses() {
      return this.$store.state.Courses.courses.filter((c) => c.page === 1);
    },
    getCoursesAreLoading() {
      return this.$store.state.Courses.coursesAreLoading;
    },
  },
  methods: {
    async GET_COURSES() {
      const response = await this.$store.dispatch("GET_COURSES", {
        limit: 10,
        page: 1,
        type: this.FIND_TYPE(2),
      });
      if (response.items.length === 0) this.$emit("return:length");
    },
  },
  mounted() {
    this.GET_COURSES();
  },
};
</script>

<style></style>
